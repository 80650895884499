import { get, post, postMultipart } from './_core'

export function initUser() {
    return get('/user');
}

export function login({ username, password, token }) {
    return post('/login',  { username, password, token })
}

export function loginGoogle(idToken) {
    return post('/login/google', {
        idToken
    })
}

export function gerRegisterCountries(lat, lng) {
    return get('/get_register_countries', {
        lat, lng
    })
}

export function registerData(lat, lng) {
    return get('/register')
}

export function register({ sex, sex2, bdate_y, bdate_m, bdate_d, country, city, nickname, email, password, passconf, iagree, token }) {
    return post('/register', {
        input: {
            sex, 
            sex2: Array.isArray(sex2) && sex2.length > 0 ? sex2 : null, 
            bdate_y, 
            bdate_m, 
            bdate_d, 
            country, 
            city, 
            nickname, 
            email, 
            password, 
            passconf, 
            iagree: iagree === true ? 1 : null,
            recaptcha: token
        }
    })
}

export function forgotPassword({ what, email, username, token }) {
    return post('/forgot_password', {
        what,
        email,
        username,
        token
    })
}

export function newPasswVerifyRequest({ id, key }) {
    return get('/newpass', { id, key })
}

export function newPassChange({ id, key, password, password2 }) {
    return post('/newpass', {
        id,
        key,
        password,
        password2
    })
}

export function getUploadedImages({ offset, limit }) {
    return get('/get_uploaded_images', {
        offset, limit
    })
}

export function uploadAttachment(file) {
    return postMultipart('/upload_image', {
        image: file
    })
}

export function getUploadedVideos({ offset, limit}) {
    return get('/uploaded_videos', {
        offset, limit
    })
}

export function uploadVideo(video) {
    return postMultipart('/upload_video', {
        video
    })
}

export function buyHot(period) {
    return post('/search/buy_hot', {
        period
    })
}

export function getWhoLikesMeCount() {
    return get('/get_who_like_me_dash')
}

export function getUsersShort(ids) {
    return post('/users/short', {
        ids
    })
}

export function getUsers(ids) {
    return post('/users', {
        ids
    })
}